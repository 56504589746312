<template>
  <div class="main-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item><i class="icon-home"></i>家庭联系簿</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="main-content-wrapper">
      <div class="main-search">
        <h2 class="main-search__title border-bottom">
          <span class="main-search__title-text">按条件搜索</span>
          <span>家庭数量：{{ totalFamilies }} 户</span>
        </h2>
        <div class="main-search__body">
          <Form :label-width="100" :model="searchData" inline>
            <FormItem label="家庭名称：">
              <Input
                v-model="searchData.jtmc"
                placeholder="请输入内容"
                style="width: 200px;"
                clearable
              ></Input>
            </FormItem>
            <FormItem label="成员名称：">
              <Input
                v-model="searchData.cymc"
                placeholder="请输入内容"
                style="width: 200px;"
                clearable
              ></Input>
            </FormItem>
            <FormItem label="成员手机号：">
              <Input
                v-model="searchData.sjh"
                placeholder="请输入内容" 
                style="width: 200px;"
                clearable
              ></Input>
            </FormItem>
            <Button type="warning" @click="handleSearch">搜索</Button>
          </Form>
        </div>
      </div>
      <div class="main-content">
        <h2 class="main-content__title border-bottom">
          <span>家庭列表</span>
          <Button type="primary" @click="showModal = true">创建家庭</Button>
        </h2>
        <div class="main-content__body">
          <Table
            :columns="familyColumns"
            :data="familyList"
            highlight-row
            @on-current-change="handleRowClick"
          ></Table>
          <div>
          <span class="records">共 {{ totalFamilies }} 条记录</span>
        </div>
          <div class="page-nations-wrapper">
            <div class="page-nations">
              <Page
                :total="totalFamilies"
                :current="searchData.page"
                :page-size="searchData.size"
                @on-change="handleChangePage"
              ></Page>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <h2 class="main-content__title border-bottom">
          成员列表{{currentFamily ? `（${currentFamily.jtmc}）` : ''}}
        </h2>
        <div class="main-content__body">
          <Table :columns="memberColumns" :data="memberList"></Table>
        </div>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="创建家庭"
      class-name="vertical-center-modal"
      width="1000"
      @on-visible-change="handleModalVisible"
      @on-ok="addFamily"
    >
      <div class="member-btn">
        <Button type="primary" @click="addMember">添加账户</Button>
      </div>
      <table class="member-table">
				<thead>
					<th style="width: 160px;">家庭关系</th>
					<th style="width: 160px;">姓名</th>
					<th style="width: 100px;">性别</th>
					<th style="width: 160px;">手机号</th>
					<th style="width: 100px;">年龄</th>
					<th>所属门店</th>
					<th style="width: 80px;">操作</th>
				</thead>
        <tbody>
          <tr v-for="(item, index) in familyMembers" :key="index">
            <td>
              <div class="member-td">
                <Select v-model="item.gx">
                  <Option value="户主">户主</Option>
                  <Option value="丈夫">丈夫</Option>
                  <Option value="妻子">妻子</Option>
                  <Option value="儿子">儿子</Option>
                  <Option value="女儿">女儿</Option>
                  <Option value="儿媳">儿媳</Option>
                  <Option value="女婿">女婿</Option>
                  <Option value="爸爸">爸爸</Option>
                  <Option value="妈妈">妈妈</Option>
                  <Option value="其他">其他</Option>
                </Select>
              </div>
            </td>
            <td>
              <Select
                v-model="item.member_id"
                @on-change="changeUser"
                filterable
              >
                <Option v-for="user in userList" :key="user.id" :value="user.id">
                  {{ user.name }}
                </Option>
              </Select>
            </td>
						<td>
              <div class="member-td">
                <span>{{ item.sex }}</span>
              </div>
            </td>
            <td>
              <div class="member-td">
                <span>{{ item.sjh }}</span>
              </div>
            </td>
            <td>
              <div class="member-td">
                <span>{{ item.age }}</span>
              </div>
            </td>
            <td>
              <div class="member-td">
                <span>{{ item.ssmd }}</span>
              </div>
            </td>
            <td>
              <Button type="error" size="small" @click="handleDelete(index)">删除</Button>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  </div>
</template>

<script>
import vTitle from 'components/topHeader/topHeader';
import familyService from '@/services/familyService';

export default {
	components: { 'v-title': vTitle },
	data() {
		return {
			searchData: {
				page: 1,
				size: 15,
				jtmc: '',
				cymc: '',
				sjh: '',
			},
			familyList: [],
			familyColumns: [
				{ title: '家庭名称', key: 'jtmc', align: 'center' },
				{ title: '户主', key: 'hz', align: 'center' },
				{ title: '性别', key: 'sex', align: 'center', width: 100 },
				{ title: '年龄', key: 'age', align: 'center', width: 100 },
				{ title: '手机号', key: 'sjh', align: 'center' },
				{ title: '家庭成员人数', key: 'jtcyrs', align: 'center', width: 100},
				{ title: '操作人', key: 'czr', align: 'center' },
				{ title: '操作时间', key: 'czsj', align: 'center' },
				{
					title: '操作',
					align: 'center',
					render: (h, params) => {
						const self = this;
						return h('div', [
							h(
								'Button',
								{
									attrs: {
										type: 'primary',
										size: 'small',
									},
									style: {
										marginRight: '5px',
									},
									on: {
										click(e) {
											e.stopPropagation();
											self.modifyFamily(params.row);
										},
									},
								},
								'修改家庭信息',
							),
							h(
								'Button',
								{
									attrs: {
										type: 'error',
										size: 'small',
									},
									on: {
										click() {
											self.handleListDelete(params.row.id);
										},
									},
								},
								'删除',
							),
						]);
					},
				},
			],
			totalFamilies: 0,
			memberList: [],
			memberColumns: [
				{ title: '家庭身份', key: 'sf', align: 'center' },
				{ title: '成员姓名', key: 'cyxm', align: 'center' },
				{ title: '性别', key: 'sex', align: 'center' },
				{ title: '年龄', key: 'age', align: 'center' },
				{ title: '手机号', key: 'sjh', align: 'center' },
				{ title: '所属门店', key: 'ssmd', align: 'center' },
				{
					title: '操作',
					align: 'center',
					render: (h, params) => {
						return h('a', {
							class: 'icon_eye',
							style: {
								marginRight: 0,
							},
							on: {
								click: () => {
									window.open(`/#/base/member/detail/${params.row.member_id}/0`, '_blank');
								},
							},
						});
					},
				},
			],
			currentFamily: null,
			showModal: false,
			familyMembers: [
				{
					age: '',
					member_id: '',
					sjh: '',
					ssmd: '',
					name: '',
					gx: '户主',
				},
			],
			userList: [],
			isAdmin: '',
		};
	},
	mounted() {
		this.getAllUsers();
		this.getFamilyList();
		this.isAdmin =
			JSON.parse(localStorage.getItem('loginInfo')).username === 'boss' ||
			JSON.parse(localStorage.getItem('loginInfo')).username === '陶正亮';
	},
	methods: {
		getAllUsers() {
			familyService.getAllUsers().then((data) => {
				this.userList = data.list;
			});
		},
		getFamilyList() {
			familyService.getFamilyList(this.searchData).then((data) => {
				this.familyList = data.list;
				this.totalFamilies = data.row_size;
			});
		},
		handleSearch() {
			this.searchData.page = 1;
			this.getFamilyList();
		},
		handleChangePage(page) {
			this.searchData.page = page;
			this.getFamilyList();
		},
		handleRowClick(row) {
			this.currentFamily = row;
			familyService.getFamilyDetail({ home_id: row.id }).then((data) => {
				this.memberList = data.list;
			});
		},
		handleModalVisible(visible) {
			if (!visible) {
				this.familyMembers = [
					{
						age: '',
						member_id: '',
						sjh: '',
						ssmd: '',
						name: '',
						gx: '户主',
					},
				];
				this.currentFamily = null;
			}
		},
		addFamily() {
			const params = {
				home_id: this.currentFamily?.id,
				cylb: JSON.stringify(
					this.familyMembers.map((item) => ({
						member_id: item.member_id,
						gx: item.gx,
					})),
				),
			};
			familyService.addFamily(params).then(() => {
				this.$Message.success('添加成功');
				this.showModal = false;
				this.getFamilyList();
			});
		},
		modifyFamily(family) {
			this.showModal = true;
			this.currentFamily = family;
			familyService.getFamilyDetail({ home_id: family.id }).then((data) => {
				this.familyMembers = data.detail.cylb.map((item) => {
					return Object.assign(this.mapUser(item.member_id), { gx: item.gx });
				});
			});
		},
		addMember() {
			this.familyMembers.push({
				gx: '',
				member_id: '',
				age: '',
				sjh: '',
				ssmd: '',
			});
		},
		mapUser(userId) {
			const res = this.userList.find((item) => item.id === userId);

			if (res) {
				const { age, phone: sjh, branch_name: ssmd, name, sex } = res;
				return { age, sjh, ssmd, name, member_id: userId, sex: sex ? '女' : '男' };
			}
		},
		changeUser(value) {
			if (!this.showModal) return;

			const target = this.familyMembers.find((item) => item.member_id === value);
			const { age, sjh, ssmd, sex } = this.mapUser(value);
			target.age = age;
			target.sjh = sjh;
			target.ssmd = ssmd;
			target.sex = sex;
		},
		handleDelete(index) {
			this.familyMembers.splice(index, 1);
		},
		handleListDelete(id) {
			if (!this.isAdmin) {
				this.$Message.warning('你没有删除权限,请联系管理员');
				return;
			}
			this.$Modal.confirm({
				title: '确定删除所选记录吗？',
				onOk: () => {
					familyService.deleteFamily({ home_id: id }).then(() => {
						this.getFamilyList();
					});
				},
			});
		},
	},
};
</script>

<style lang="less" scoped>
.member-btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.member-table {
  width: 100%;
	th {
		text-align: left;
		padding: 5px 10px 5px 0;
	}
  td {
    padding: 5px 10px 5px 0;
  }
}
</style>